import React,{useState} from 'react';
import data from './resultData.json';
import IqubeName from './IqubeName.png';
import Iqube_white from './IQUBE_white.png';
import './App.css';


const newData = data;
let map = new Map();
  for(let obj of newData){
    map.set(obj['Seat Number'], obj);
    
  }
  
  // let nameMap = new Map();
  // for(let obj of newData){
  //   nameMap.set(obj['Name'], obj);
    
  // }

function App() {
  const [inputNum, setInputNum] = useState('');
  const [student, setStudent] = useState();
  const [studentObj, setStudentObj] = useState([]);
  

  const showResult = () =>{
    // console.log(Number(inputNum))
    if(!isNaN(inputNum)){
      setStudentObj([]);
      let newNum = Number(inputNum);
     let result = map.get(newNum);
     setStudent(result);
    }
    else if( isNaN(inputNum) && inputNum !==''){
            setStudent();
            var arr = [];
      var rgxp = new RegExp(inputNum, "gi")
        for(let obj of newData){
          if(obj['Name'].match(rgxp)){
          arr.push(obj);
        }
      }
      if(arr.length === 0){
        let reverseString = inputNum.split(' '); 
        if(reverseString.length === 2){
          let srStrtStudent = reverseString[1] + ' ' +reverseString[0]; 
          let srStrtStudent2 = reverseString[0] + ' ' +reverseString[1]; 
          let ForRgxp = new RegExp(srStrtStudent, "gi");
          let ForRgxp2 = new RegExp(srStrtStudent2, "gi");
          let regex4 = new RegExp("^(?:" + srStrtStudent.split(" ").join(" [A-Za-z]+ ") + ")$", "i");
          let regex2 = new RegExp("^(?:" + srStrtStudent2.split(" ").join(" [A-Za-z]+ ") + ")$", "i");
      for(let obj of newData){
            if(obj['Name'].match(ForRgxp)){
            arr.push(obj);
          }
          else if(obj['Name'].match(ForRgxp2)){
            arr.push(obj);
          }
          else if(obj['Name'].match(regex4)){
            arr.push(obj);
          }
          else if(obj['Name'].match(regex2)){
            arr.push(obj);
          }
       
        }
        }
        else if(reverseString.length === 3){
          let surnameStrt = reverseString[0] + ' ' +reverseString[1] + ' ' +reverseString[2];
          let surnameStrt2 = reverseString[1] + ' ' +reverseString[2] + ' ' +reverseString[0];
          let surnameStrt3 = reverseString[0] + ' ' +reverseString[2] + ' ' +reverseString[1];
          let surnameStrt4 = reverseString[2] + ' ' +reverseString[1] + ' ' +reverseString[0];
          let surnameStrt5 = reverseString[1] + ' ' +reverseString[0] + ' ' +reverseString[2];
          let surnameStrt6 = reverseString[2] + ' ' +reverseString[0] + ' ' +reverseString[1];
          let forRgxpStartWithSurname = new RegExp(surnameStrt, "gi");
          let forRgxpStartWithSurname2 = new RegExp(surnameStrt2, "gi");
          let forRgxpStartWithSurname3 = new RegExp(surnameStrt3, "gi");
          let forRgxpStartWithSurname4 = new RegExp(surnameStrt4, "gi");
          let forRgxpStartWithSurname5 = new RegExp(surnameStrt5, "gi");
          let forRgxpStartWithSurname6 = new RegExp(surnameStrt6, "gi");
          for(let obj of newData){
            if(obj['Name'].match(forRgxpStartWithSurname)){
            arr.push(obj);
          }
          else if(obj['Name'].match(forRgxpStartWithSurname2)){
            arr.push(obj)
          }
          else if(obj['Name'].match(forRgxpStartWithSurname3)){
            arr.push(obj)
          }else if(obj['Name'].match(forRgxpStartWithSurname4)){
            arr.push(obj)
          }else if(obj['Name'].match(forRgxpStartWithSurname5)){
            arr.push(obj)
          }else if(obj['Name'].match(forRgxpStartWithSurname6)){
            arr.push(obj)
          }
         
        }
        
        }
        // else{
        //   alert("Student not found...please Enter correct Seat Number or Name")
        // }
      }
     
     setStudentObj(arr)  
      
    }
    
    else{
      alert("Student not found...please Enter correct Seat Number or Name")
    }
    
   }
  
  

  return (
   <>
   <div className="app">
   <div className="header">
    <div className="logo">
      <img src={IqubeName} alt="IQUBE NAME" className='image-logo1'/>
      <img src={Iqube_white} alt="IQUBE LOGO" className='image-logo2' />
    </div>
    <div className="header-title">
      <div className='header-title1'>
    <h1>I QUBE RESULT 2024-2025</h1>
    </div>
    </div>
    </div>
    <div className='card-container'>
      <div className='card-1'>
        <div className='card-title-1'> <p>Results for I Qube All India Open Intelligence Scholarship Examination 2023-2024 are available online. Hard copies of the results will be submitted in the school once it re-opens in June 2024.</p> </div>
      </div>
      <div className='card-2'>
        <div className='card-title-1'><p>Scholarships to all the eligible students will be given in the form of cheques. All the cheques, marksheets and certificates will be handed over to the students by the school authorities / coordinators once the school re-opens. Validity of the issued cheques is 3 months from the date of issue</p></div>
      </div>
    </div>
    <div className='search-instruction'>
      <div className='search-instruction-field'>
        <p>Results can be searched by entering your Seat Number or Name</p>
      </div>
    
    <div className='search-field'> 
    <input type="text" id="seat_number_input"  title='Sheat Number'
      placeholder='Enter your Seat Number Or Name, Start your name with Surname...'value={inputNum}
      onChange={(e)=>{setInputNum(e.target.value)}} className='inputField'/>
      <button className='btn' onClick={showResult}>Show Result</button>

    </div>
    </div>

    <div className='container-i-student'>
    { student && 
  <>
  <div className='student-info-container'>
   <div className='upper-div'>
  <div className='result-header'>
    <h2 className='result-heading'>Student Information</h2>
  </div>
  </div>
   <div className='student-info'>
     <div className='seat-number'>
       <h4 className='header2'>Seat No</h4>
       <p>{student["Seat Number"]}</p>
     </div>
     <div className='name'>
       <h4 className='header2'>Name</h4>
       <p>{student["Name"]}</p>
     </div>
   </div>
   <div className='upper-div'>
  <div className='result-header'>
    <h2 className='result-heading'>School Information</h2>
  </div>
  </div>
   <div className='school-info'>
     <div className='school-name'>
     <h4 className='header2'>School Name</h4>
       <p>{student["School Name"]}</p>
     </div>
     <div className='grade'>
     <h4 className='header2'>Grade</h4>
       <p>{student["Grade"]}</p>
     </div>
     <div className='div'>
     <h4 className='header2'>Div</h4>
       <p>{student["Div"]}</p>
     </div>
   </div>
   <div className='upper-div'>
  <div className='result-header'>
    <h2 className='result-heading'>Result</h2>
  </div>
  </div>
  <div className='result'>
     <div className='total-number'>
       <h4 className='header2'>Total Score Out of 150</h4>
       <p className='circle'>{student["Total Score Out of 150"]}</p>
     </div>
     <div className='percentage'>
       <h4 className='header2'>Percentage</h4>
       <p className='circle'>{(student["Percentage"]*100).toFixed(2)}%</p>
     </div>
    </div>
    <div className='s-v-percentage'>
       <div className='r-percentage'>
       <h4>Reasoning Percentage</h4>
       <p className='circle'>{(student["Reasoning Percentage"]*100).toFixed(2)}%</p>
       </div>
       <div className='a-percentage'>
       <h4>Analysis Percentage</h4>
       <p className='circle'>{(student["Analysis Percentage"]*100).toFixed(2)}%</p>
       </div>
       <div className='p-percentage'>
       <h4>Problem Solving Percentage</h4>
       <p className='circle'>{(student["Problem Solving Percentage"]*100).toFixed(2)}%</p>
       </div>
       <div className='s-percentage'>
       <h4>Social, Memory and Linguistics Percentage</h4>
       <p className='circle'>{(student["Social, Memory and Linguistics Percentage"]*100).toFixed(2)}%</p>
       </div>
     </div>
  <div className='upper-div'>
  <div className='all-india-rank'>
    <h2 className='result-heading'>All India Rank</h2>
    <p className='circle'>{student["All India Rank"]}</p>
  </div>
  </div>
  <div className='margin-bottom'></div>
</div>
</>
 } 
{ studentObj.length !==0 && studentObj.map(student=>{
      return (  <>
        <div className='student-info-container' key={student["Seat Number"]}>
         <div className='upper-div'>
        <div className='result-header'>
          <h2 className='result-heading'>Student Information</h2>
        </div>
        </div>
         <div className='student-info'>
           <div className='seat-number'>
             <h4 className='header2'>Seat No</h4>
             <p>{student["Seat Number"]}</p>
           </div>
           <div className='name'>
             <h4 className='header2'>Name</h4>
             <p>{student["Name"]}</p>
           </div>
         </div>
         <div className='upper-div'>
        <div className='result-header'>
          <h2 className='result-heading'>School Information</h2>
        </div>
        </div>
         <div className='school-info'>
           <div className='school-name'>
           <h4 className='header2'>School Name</h4>
             <p>{student["School Name"]}</p>
           </div>
           <div className='grade'>
           <h4 className='header2'>Grade</h4>
             <p>{student["Grade"]}</p>
           </div>
           <div className='div'>
           <h4 className='header2'>Div</h4>
             <p>{student["Div"]}</p>
           </div>
         </div>
         <div className='upper-div'>
        <div className='result-header'>
          <h2 className='result-heading'>Result</h2>
        </div>
        </div>
        <div className='result'>
           <div className='total-number'>
             <h4 className='header2'>Total Score Out of 150</h4>
             <p className='circle'>{student["Total Score Out of 150"]}</p>
           </div>
           <div className='percentage'>
             <h4 className='header2'>Percentage</h4>
             <p className='circle'>{(student["Percentage"]*100).toFixed(2)}%</p>
           </div>
          </div>
          <div className='s-v-percentage'>
             <div className='r-percentage'>
             <h4>Reasoning Percentage</h4>
             <p className='circle'>{(student["Reasoning Percentage"]*100).toFixed(2)}%</p>
             </div>
             <div className='a-percentage'>
             <h4>Analysis Percentage</h4>
             <p className='circle'>{(student["Analysis Percentage"]*100).toFixed(2)}%</p>
             </div>
             <div className='p-percentage'>
             <h4>Problem Solving Percentage</h4>
             <p className='circle'>{(student["Problem Solving Percentage"]*100).toFixed(2)}%</p>
             </div>
             <div className='s-percentage'>
             <h4>Social, Memory and Linguistics Percentage</h4>
             <p className='circle'>{(student["Social, Memory and Linguistics Percentage"]*100).toFixed(2)}%</p>
             </div>
           </div>
        <div className='upper-div'>
        <div className='all-india-rank'>
          <h2 className='result-heading'>All India Rank</h2>
          <p className='circle'>{student["All India Rank"]}</p>
        </div>
        </div>
        <div className='margin-bottom'></div>
      </div>
      </>)
    
 })
    
 }
    </div>
 </div>
   </>
  );
}

export default App;
